<template>
    <div class="course-list-item">
        <div>{{ data.label }}</div>
        <div v-if="data.time">{{ data.time }}</div>
        <div v-if="type =='2'">{{ data.gradeName }}</div>
    </div>
</template>
<script>
export default {
    name: "CourseListItem",
    props: {
        data: Object,
        type:String
    }
}
</script>

<style lang="scss" scoped>
.course-list-item {
    width: 136px;
    // height: 82px;
    height: 105px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    // color: #808487;
    color: #FFFFFF;
    background-color: #2288F1;
    // border-right: 1px solid #EDF0F2;
    // border-bottom: 1px solid #EDF0F2;
    border-right: 1px solid #61AFFF;
    border-bottom: 1px solid #61AFFF;
}
</style>