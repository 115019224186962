<template>
    <div>
        <!-- <div class="course-schedule-view" :style="{'max-width': weekList.length>0?weekList.length*172+136+'px':1200+'px'}" id="pdfPreviewDom1"> -->
            <div class="course-schedule-view" :style="{'max-width': weekList.length*172+136<1360 && weekList.length!=0   ?weekList.length*172+136+'px':1360+'px'}" id="pdfPreviewDom1">
            <!-- <div class="course-schedule-view" :style="{'max-width': 1340+'px'}" id="pdfPreviewDom1"> -->
            <CourseTableTitle :classAndTeacher="classAndTeacher" :type="type" class="course-table-title"></CourseTableTitle>
            <div v-if="loding" class="course-schedule-view-content">
                <CourseList
                :courseList="courseList"
                :queryList="queryList"
                :isTourClass="isTourClass"
                :type="type"
                />
                <WeekList
                v-if="queryList.dayWeek === 'week' && !getDataLoading"
                :advanceTime="advanceTime"
                :weekList="weekList"
                :showDownloadPDFType="showDownloadPDFType"
                :isTourClass="isTourClass"
                :courseList="courseList"
                :type="type"
                />
                <DayList
                v-if="queryList.dayWeek === 'day' && !getDataLoading"
                :dayList="dayList"
                :isTourClass="isTourClass"
                :courseList="courseList"
                :type="type"
                :courseScheduleDetailSubjectsList="courseScheduleDetailSubjectsList"
                :courseScheduleDetailTeachersList="courseScheduleDetailTeachersList"
                :queryList="queryList"
                />
            </div>
        
    </div>
    </div>
    
</template>
<script>
import CourseTableTitle from './CourseScheduleView/CourseTableTitle.vue';
import CourseList from './CourseScheduleView/CourseList.vue';
import WeekList from './CourseScheduleView/WeekList.vue';
import DayList from './CourseScheduleView/DayList.vue';

export default {
    name: "CourseScheduleView",
    components: {
        CourseList,
        WeekList,
        DayList,
        CourseTableTitle
    },
    props: {
        classAndTeacher:Object,
        queryList: Object,
        courseList: Array,
        weekList: Array,
        dayList: Array,
        isTourClass: String,
        type: String,
        courseScheduleDetailSubjectsList: Array,
        courseScheduleDetailTeachersList: Array,
        showDownloadPDFType: Boolean,
        getDataLoading: Boolean,
        loding:{
            default:true,
        },
        advanceTime: Number
    },
}
</script>

<style lang="scss" scoped>
    .course-schedule-view {
        border: 1px solid #61AFFF;
        margin: auto;
        margin-top: 24px;
        overflow-x: scroll;
        // max-width: 1340px;
        
        .course-table-title{
            height: 48px;
            width: 100%;
        }
        .course-schedule-view-content{
            height: calc(100vh - 300px);
            // max-width: 1340px;
            
            overflow: auto;
            display: flex;
            background-color: #FAFAFA;
        }
        border-radius: 8px;
        // overflow: hidden;
    }
</style>
