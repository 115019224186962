<template>
    <div class="export-pdf-preview">
        <el-button type="primary" @click="pdfDownload()">下载PDF</el-button>
        <div id="pdfPreviewDom" ref="pdfPreviewDom">
            <slot></slot>
        </div>
    </div>
</template>
<script>
import html2canvas from 'html2canvas'
import JsPDF from 'jspdf'

export default {
    name: "ExportPdfPreview",
    props: {
        courseList: {
            type: Array
        },
        weekList: {
            type: Array
        }
    },
    methods: {
        pdfDownload () {
            // 106 为表格横向高度
            const mainRight = document.getElementById('pdfPreviewDom1'),
                 _domInfo = document.getElementById('pdfPreviewDom1').getBoundingClientRect(),
                 hg = this.courseList.length * 106 + 283,
                 wh = (this.weekList.length + 1) * 213 + 213,
                 slHg = document.getElementById('pdfPreviewDom1').scrollHeight +  hg;
            console.log(wh,_domInfo.width,'width11')
            // return;
            html2canvas(mainRight, {
                allowTaint: true,
                useCORS: true,
                scale: 1, // 提升画面质量，但是会增加文件大小
                width: wh,
                height: hg,
                windowWidth: wh,
                windowHeight: slHg
            }).then(function (canvas) {
                console.log(canvas.height,canvas,'canvas')
                let pageData = canvas.toDataURL('image/jpeg', 1),
                    canvasWidth = canvas.width,
                    canvasHeight = canvas.height,
                    concentWidth = 500,
                    concentHeight = Math.round((concentWidth / canvasWidth) * canvasHeight),
                    position = 72,
                    pageHeight = 892,
                    height = concentHeight;
                console.log(pageData, height, pageHeight)
                // 新建一个new JsPDF，A3的像素大小 842*1191，A4的像素大小 592*841。这个px像素不准确，不清楚他们的像素大小来源如何
                let PDF = new JsPDF('p', 'px', 'a3')
                if (height <= pageHeight) {
                    // 添加图片
                    PDF.addImage(pageData, 'JPEG', 68, position, concentWidth, concentHeight)
                } else {
                    console.log(height,'进入否则')
                    while (height > 0) {
                        PDF.addImage(pageData, 'JPEG', 68, position, concentWidth, concentHeight)
                        height -= pageHeight
                        position -= pageHeight
                        if (height > 0) {
                            PDF.addPage()
                        }
                    }
                }

                PDF.save('课程表' + '.pdf');
            });
        },
    }
}
</script>

<style lang="scss">
 
</style>